.Loader,
.Loader:before,
.Loader:after {
  border-radius: 50%;
}
.Loader {
  color: var(--primary-text-color);
  font-size: 3px;
  text-indent: -99999999em;
  margin: 5rem auto;
  position: relative;
  width: 10em;
  height: 10em;
  box-shadow: inset 0 0 0 1em;
  transform: translateZ(0);
}
.Loader:before,
.Loader:after {
  position: absolute;
  content: '';
}
.Loader:before {
  width: 50%;
  height: 100%;
  background: var(--white);
  border-radius: 10em 0 0 10em;
  box-shadow: 0 0 0 1px var(--white);
  top: 0;
  left: 0;
  transform-origin: 5em 5em;
  animation: load2 2s infinite ease 1.5s;
}
.Loader:after {
  width: 50%;
  height: 100%;
  background: var(--white);
  border-radius: 0 10em 10em 0;
  box-shadow: 0 0 0 1px var(--white);
  top: 0;
  left: 50%;
  transform-origin: 0px 5em;
  animation: load2 2s infinite ease;
}
@-webkit-keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
