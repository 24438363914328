@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');

:root {
  /* COLORS */
  --primary-text-color: #22447E;
  --secondary-text-color: #90A1BE;
  --cta-color: #E54430;
  --cta-hover-color: #E85F4E;
  --border-color: #E8ECF2;
  --primary-background-color: #fff;
  --secondary-background-color: #E5F0F2;
  --white: #fff;

  /* TYPE */
  --font-family: 'Roboto', sans-serif;

  --extra-large-font-size: 3.2rem;
  --large-font-size: 2.2rem;
  --medium-font-size: 1.6rem;
  --small-font-size: 1.4rem;
  --extra-small-font-size: 1.1rem;

  --light: 300;
  --regular: 400;
  --medium: 500;
  --bold: 700;

  /* OTHER */
  --desktop-max-width: 111rem;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 10px;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-family);
  font-size: var(--medium-font-size);
  font-weight: var(--regular);
  color: var(--primary-text-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}

