.container {
  display: flex;
  align-items: center;
  justify-content: left;
  min-height: 100vh;
  /* background-image: url('/images/login-bg.jpg'); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.logoImage {
  width: 154px;
  height: auto;
  margin-bottom: 22px;
}

.textContainer {
  max-width: 380px;
  margin-left: 110px;
}

@media only screen and (max-width: 72rem) {
  .textContainer {
    margin: 0 3rem;
  }
}

.textTitle {
  font-size: var(--extra-large-font-size);
  letter-spacing: 1px;
  line-height: 28px;
  color: var(--white);
}

.textSubtitle {
  font-size: var(--font-size);
  font-weight: 400;
  margin-top: 4px;
  margin-bottom: 30px;
  letter-spacing: 0;
  color: var(--white);
}

.link {
  color: var(--white);
  background-color: var(--cta-color);
  padding: 1.1rem 4rem;
  text-decoration: none;
  text-transform: uppercase;
  font-size: var(--font-size);
  font-weight: 400;
  display: inline-block;
  letter-spacing: 0.5px;
}
