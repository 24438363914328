@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700);
:root {
  /* COLORS */
  --primary-text-color: #22447E;
  --secondary-text-color: #90A1BE;
  --cta-color: #E54430;
  --cta-hover-color: #E85F4E;
  --border-color: #E8ECF2;
  --primary-background-color: #fff;
  --secondary-background-color: #E5F0F2;
  --white: #fff;

  /* TYPE */
  --font-family: 'Roboto', sans-serif;

  --extra-large-font-size: 3.2rem;
  --large-font-size: 2.2rem;
  --medium-font-size: 1.6rem;
  --small-font-size: 1.4rem;
  --extra-small-font-size: 1.1rem;

  --light: 300;
  --regular: 400;
  --medium: 500;
  --bold: 700;

  /* OTHER */
  --desktop-max-width: 111rem;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 10px;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-family);
  font-size: var(--medium-font-size);
  font-weight: var(--regular);
  color: var(--primary-text-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}


.Login_container__2dvnv {
  display: flex;
  align-items: center;
  justify-content: left;
  min-height: 100vh;
  /* background-image: url('/images/login-bg.jpg'); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.Login_logoImage__ywOch {
  width: 154px;
  height: auto;
  margin-bottom: 22px;
}

.Login_textContainer__2uAr7 {
  max-width: 380px;
  margin-left: 110px;
}

@media only screen and (max-width: 72rem) {
  .Login_textContainer__2uAr7 {
    margin: 0 3rem;
  }
}

.Login_textTitle__8hmCI {
  font-size: var(--extra-large-font-size);
  letter-spacing: 1px;
  line-height: 28px;
  color: var(--white);
}

.Login_textSubtitle__fwTo8 {
  font-size: var(--font-size);
  font-weight: 400;
  margin-top: 4px;
  margin-bottom: 30px;
  letter-spacing: 0;
  color: var(--white);
}

.Login_link__2tILF {
  color: var(--white);
  background-color: var(--cta-color);
  padding: 1.1rem 4rem;
  text-decoration: none;
  text-transform: uppercase;
  font-size: var(--font-size);
  font-weight: 400;
  display: inline-block;
  letter-spacing: 0.5px;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem;
  margin: 0 auto;
  max-width: var(--desktop-max-width);
}

.Header_logoIconContainer__3LY-P {
  position: relative;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
}

.Header_logoIconText__2NJMy {
  margin-top: -3px;
  margin-left: 1rem;
  color: #163F89;
  font-weight: 500;
}

.Header_logoIconContainer__3LY-P {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.Header_logoIconContainer__3LY-P::after {
  content: 'ALPHA';
  position: absolute;
  bottom: -1px;
  right: 0;
  display: block;
  font-size: 1.1rem;
  text-decoration: none;
  color: var(--primary-text-color);
}

.Header_actionBtn__Izyyi {
  border: none;
  border-radius: .3rem;
  background-color: var(--primary-text-color);
  background-repeat: no-repeat;
  background-position: center center;
  color: var(--white);
  font-size: var(--small-font-size);
  line-height: var(--small-font-size);
  padding: 1.3rem 3rem;
  height: 4.2rem;
  flex-shrink: 0;
  cursor: pointer;
}

.Loader_Loader__1sTs0,
.Loader_Loader__1sTs0:before,
.Loader_Loader__1sTs0:after {
  border-radius: 50%;
}
.Loader_Loader__1sTs0 {
  color: var(--primary-text-color);
  font-size: 3px;
  text-indent: -99999999em;
  margin: 5rem auto;
  position: relative;
  width: 10em;
  height: 10em;
  box-shadow: inset 0 0 0 1em;
  transform: translateZ(0);
}
.Loader_Loader__1sTs0:before,
.Loader_Loader__1sTs0:after {
  position: absolute;
  content: '';
}
.Loader_Loader__1sTs0:before {
  width: 50%;
  height: 100%;
  background: var(--white);
  border-radius: 10em 0 0 10em;
  box-shadow: 0 0 0 1px var(--white);
  top: 0;
  left: 0;
  transform-origin: 5em 5em;
  animation: Loader_load2__2tHVa 2s infinite ease 1.5s;
}
.Loader_Loader__1sTs0:after {
  width: 50%;
  height: 100%;
  background: var(--white);
  border-radius: 0 10em 10em 0;
  box-shadow: 0 0 0 1px var(--white);
  top: 0;
  left: 50%;
  transform-origin: 0px 5em;
  animation: Loader_load2__2tHVa 2s infinite ease;
}
@keyframes Loader_load2__2tHVa {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

